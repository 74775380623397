<script lang="ts">
    import { onMount } from 'svelte';
    import type { Attachment, Post } from '../../interfaces/types';

    export let attachment: Attachment;
    export let post: Post;
    export let currentUser: any; // Replace 'any' with your User type
    export let hasVideoAttachment: boolean;

    let videoElement: HTMLVideoElement;

    const isConverting =
        currentUser &&
        ((post.user_id === currentUser.id && post.status === 0 && hasVideoAttachment) ||
            (currentUser.role_id === 1 && post.status === 0 && hasVideoAttachment));

    const password = new Date(attachment.created_at).toISOString().replace(/[-:T]/g, '').slice(0, 14);

    onMount(async () => {
        // Load necessary scripts
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/video.js/7.8.2/video.min.js');
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/dashjs/3.1.3/dash.all.min.js');

        initPlayer();
    });

    function loadScript(src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
            document.head.appendChild(script);
        });
    }

    function initPlayer() {
        const protData = {
            'org.w3.clearkey': {
                clearkeys: {
                    '64f2e2bab748d26fd8c26926a07fefed': 'bf601d0c8423f5a09b95488e3a2f47ee',
                },
                serverCertificate: null,
                licenseServerUrl: '',
            },
        };

        const player = (window as any).dashjs.MediaPlayer().create();

        player.initialize(videoElement, attachment.filename, false);
        player.setProtectionData(protData);

        player.updateSettings({
            streaming: {
                stableBufferTime: 20,
                bufferTimeAtTopQuality: 30,
                bufferToKeep: 30,
                bufferPruningInterval: 30,
                lowLatencyEnabled: false,
            },
        });

        videoElement.addEventListener('loadedmetadata', () => {
            console.log('Video width:', videoElement.videoWidth);
            console.log('Video height:', videoElement.videoHeight);
            console.log('Audio tracks:', player.getTracksFor('audio'));
        });
    }

    function check() {
        if (
            typeof window !== 'undefined' &&
            window.location.protocol === 'http:' &&
            window.location.hostname !== 'localhost'
        ) {
            const warningElement = document.getElementById('http-warning');
            if (warningElement) {
                warningElement.innerHTML =
                    'This page has been loaded under http. This might result in the EME APIs not being available to the player and any DRM-protected content will fail to play. ' +
                    "If you wish to test manifest URLs that require EME support, then <a href='https:" +
                    window.location.href.substring(window.location.protocol.length) +
                    "'>reload this page under https</a>.";
                warningElement.style.display = 'block';
            }
        }
    }
</script>

<div class="container">
    <video bind:this="{videoElement}" id="my-video-{attachment.id}" class="video-js vjs-default-skin" controls>
        Your browser does not support HTML5 video.
    </video>
</div>

<div id="http-warning" style="display: none;"></div>

<svelte:window on:load="{check}" />

<style>
    .container {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }

    .video-js {
        width: 100%;
        height: auto;
    }
</style>
